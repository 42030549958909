import BIN_DATA, { BinDayInformation } from "../data/orrell-bins";
import { differenceInDays } from "date-fns";

export const getNextBinDay = (
  todaysDate: Date
): BinDayInformation | undefined => {
  return BIN_DATA.find(({ date }) => {
    return differenceInDays(todaysDate, new Date(date.toLocaleString())) <= 0;
  });
};
