type BinDate = string;

export type BinColor = "BLACK" | "BROWN" | "GREEN" | "BLUE";

export interface BinDayInformation {
  date: BinDate;
  bins: BinColor[];
  dayChange?: string;
}

const BIN_DATES: BinDayInformation[] = [
  { date: "07/4/2023", bins: ["BLACK", "GREEN"] },
  { date: "07/11/2023", bins: ["BROWN"] },
  { date: "07/18/2023", bins: ["BLUE", "GREEN"] },
  { date: "07/25/2023", bins: ["BLACK"] },
  { date: "08/1/2023", bins: ["BROWN", "GREEN"] },
  { date: "08/8/2023", bins: ["BLUE"] },
  { date: "08/15/2023", bins: ["BLACK", "GREEN"] },
  { date: "08/22/2023", bins: ["BROWN"] },
  { date: "08/29/2023", bins: ["BLUE", "GREEN"] },
  { date: "09/5/2023", bins: ["BLACK"] },
  { date: "09/12/2023", bins: ["BROWN", "GREEN"] },
  { date: "09/19/2023", bins: ["BLUE"] },
  { date: "09/26/2023", bins: ["BLACK", "GREEN"] },
  { date: "10/3/2023", bins: ["BROWN"] },
  { date: "10/10/2023", bins: ["BLUE", "GREEN"] },
  { date: "10/17/2023", bins: ["BLACK"] },
  { date: "10/24/2023", bins: ["BROWN", "GREEN"] },
  { date: "10/31/2023", bins: ["BLUE"] },
  { date: "11/7/2023", bins: ["BLACK", "GREEN"] },
  { date: "11/14/2023", bins: ["BROWN"] },
  { date: "11/21/2023", bins: ["BLUE", "GREEN"] },
  { date: "11/28/2023", bins: ["BLACK"] },
  { date: "12/5/2023", bins: ["BROWN", "GREEN"] },
  { date: "12/12/2023", bins: ["BLUE"] },
  { date: "12/19/2023", bins: ["BLACK", "GREEN"] },
  { date: "12/27/2023", bins: ["BROWN"], dayChange: "Wednesday" },
  { date: "01/2/2024", bins: ["BLUE"] },
  { date: "01/9/2024", bins: ["BLACK"] },
  { date: "01/16/2024", bins: ["BROWN", "GREEN"] },
  { date: "01/23/2024", bins: ["BLUE"] },
  { date: "01/30/2024", bins: ["BLACK", "GREEN"] },
  { date: "02/6/2024", bins: ["BROWN"] },
  { date: "02/13/2024", bins: ["BLUE", "GREEN"] },
  { date: "02/20/2024", bins: ["BLACK"] },
  { date: "02/27/2024", bins: ["BROWN", "GREEN"] },
  { date: "03/5/2024", bins: ["BLUE"] },
  { date: "03/12/2024", bins: ["BLACK", "GREEN"] },
  { date: "03/19/2024", bins: ["BROWN"] },
  { date: "03/25/2024", bins: ["BLUE", "GREEN"], dayChange: "Monday" },
  { date: "04/2/2024", bins: ["BLACK"] },
  { date: "04/9/2024", bins: ["BROWN", "GREEN"] },
  { date: "04/16/2024", bins: ["BLUE"] },
  { date: "04/23/2024", bins: ["BLACK", "GREEN"] },
  { date: "04/30/2024", bins: ["BROWN"] },
  { date: "05/7/2024", bins: ["BLUE", "GREEN"] },
  { date: "05/14/2024", bins: ["BLACK"] },
  { date: "05/21/2024", bins: ["BROWN", "GREEN"] },
  { date: "05/28/2024", bins: ["BLUE"] },
  { date: "06/4/2024", bins: ["BLACK", "GREEN"] },
  { date: "06/11/2024", bins: ["BROWN"] },
  { date: "06/18/2024", bins: ["BLUE", "GREEN"] },
  { date: "06/25/2024", bins: ["BLACK"] },
  { date: "07/2/2024", bins: ["BROWN", "GREEN"] },
  { date: "07/9/2024", bins: ["BLUE"] },
  { date: "07/16/2024", bins: ["BLACK", "GREEN"] },
  { date: "07/23/2024", bins: ["BROWN"] },
  { date: "07/30/2024", bins: ["BLUE", "GREEN"] },
  { date: "08/6/2024", bins: ["BLACK"] },
  { date: "08/13/2024", bins: ["BROWN", "GREEN"] },
  { date: "08/20/2024", bins: ["BLUE"] },
  { date: "08/27/2024", bins: ["BLACK", "GREEN"] },
  { date: "09/3/2024", bins: ["BROWN"] },
  { date: "09/10/2024", bins: ["BLUE", "GREEN"] },
  { date: "09/17/2024", bins: ["BLACK"] },
  { date: "09/24/2024", bins: ["BROWN", "GREEN"] },
  { date: "10/1/2024", bins: ["BLUE"] },
  { date: "10/8/2024", bins: ["BLACK", "GREEN"] },
  { date: "10/15/2024", bins: ["BROWN"] },
  { date: "10/22/2024", bins: ["BLUE", "GREEN"] },
  { date: "10/29/2024", bins: ["BLACK"] },
  { date: "11/5/2024", bins: ["BROWN", "GREEN"] },
  { date: "11/12/2024", bins: ["BLUE"] },
  { date: "11/19/2024", bins: ["BLACK", "GREEN"] },
  { date: "11/26/2024", bins: ["BROWN"] },
  { date: "12/3/2024", bins: ["BLUE", "GREEN"] },
  { date: "12/10/2024", bins: ["BLACK"] },
  { date: "12/17/2024", bins: ["BROWN", "GREEN"] },
  { date: "12/23/2024", bins: ["BLUE"], dayChange: "Monday" },
  { date: "12/31/2024", bins: ["BLACK"] },
  { date: "01/7/2025", bins: ["BROWN"] },
  { date: "01/14/2025", bins: ["BLUE", "GREEN"] },
  { date: "01/21/2025", bins: ["BLACK"] },
  { date: "01/28/2025", bins: ["BROWN", "GREEN"] },
  { date: "02/4/2025", bins: ["BLUE"] },
  { date: "02/11/2025", bins: ["BLACK", "GREEN"] },
  { date: "02/18/2025", bins: ["BROWN"] },
  { date: "02/25/2025", bins: ["BLUE", "GREEN"] },
  { date: "03/4/2025", bins: ["BLACK"] },
  { date: "03/11/2025", bins: ["BROWN", "GREEN"] },
  { date: "03/18/2025", bins: ["BLUE"] },
  { date: "03/25/2025", bins: ["BLACK", "GREEN"] },
  { date: "04/1/2025", bins: ["BROWN"] },
  { date: "04/8/2025", bins: ["BLUE", "GREEN"] },
  { date: "04/14/2025", bins: ["BLACK"], dayChange: "Monday" },
  { date: "04/22/2025", bins: ["BROWN", "GREEN"] },
  { date: "04/29/2025", bins: ["BLUE"] },
  { date: "05/6/2025", bins: ["BLACK", "GREEN"] },
  { date: "05/13/2025", bins: ["BROWN"] },
  { date: "05/20/2025", bins: ["BLUE", "GREEN"] },
  { date: "05/27/2025", bins: ["BLACK"] },
  { date: "06/3/2025", bins: ["BROWN", "GREEN"] },
  { date: "06/10/2025", bins: ["BLUE"] },
  { date: "06/17/2025", bins: ["BLACK", "GREEN"] },
  { date: "06/24/2025", bins: ["BROWN"] },
];

export default BIN_DATES;
