import React from "react";
import { BinColor } from "../data/orrell-bins";

function getBinImage(bin: BinColor) {
  switch (bin) {
    case "BROWN":
      return "/images/brown.png";
    case "BLUE":
      return "/images/blue.png";
    case "GREEN":
      return "/images/green.png";
    default:
      return "/images/black.png";
  }
}

const BinCard: React.FC<{ bin: BinColor }> = ({ bin }) => {
  const binImage = getBinImage(bin);
  return (
    <li className="bin-card">
      <img src={binImage} alt={`${bin.toLowerCase()} bin`} />
      <h2>{bin} BIN</h2>
    </li>
  );
};

export default BinCard;
