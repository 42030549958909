import React, { useState } from "react";
import { format } from "date-fns";
import { GoTrash, GoChevronLeft, GoChevronRight } from "react-icons/go";

import "./App.css";
import { getNextBinDay } from "./utils/getNextBinDay";
import BinCard from "./components/BinCard";

const TODAY = new Date();

function addWeek(date: Date) {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() + 7 * 1);
  return dateCopy;
}

function subtractWeek(date: Date) {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() - 7 * 1);
  return dateCopy;
}

const App = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(TODAY);
  const nextBinDay = getNextBinDay(selectedDate);

  const handleWeekChange = (type: string) => {
    if (type === "next") {
      setSelectedDate(addWeek(selectedDate));
    } else {
      setSelectedDate(subtractWeek(selectedDate));
    }
  };

  if (!nextBinDay) {
    return (
      <div className="App">
        <header className="App-header">Something Went Wrong</header>
      </div>
    );
  }

  const { date, bins } = nextBinDay;

  return (
    <div className="app">
      <header className="header">
        <GoTrash />
        <h1>Orrell Bins</h1>
      </header>
      <main className="wrapper">
        <div className="container">
          <GoChevronLeft
            className="chevron"
            onClick={() => handleWeekChange("prev")}
          />
          <p>
            Next Bin Collection - {format(new Date(date), "EEEE do LLLL yyyy")}
          </p>
          <GoChevronRight
            className="chevron"
            onClick={() => handleWeekChange("next")}
          />
        </div>
        <ul>
          {bins.map((bin) => (
            <BinCard key={bin} bin={bin} />
          ))}
        </ul>
      </main>
      <footer className="footer">
        <a
          href="https://www.craig-jones.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/cj-logo.png" alt="Craig Jones" />
        </a>
      </footer>
    </div>
  );
};

export default App;
